///<reference path="inc/AppConfig.ts"/>
///<reference path="inc/AppController.ts"/>
///<reference path="../def/angular.d.ts"/>

declare var MaterialDatetimePicker;

var app:any = angular.module('xCrmApp', ['ngMaterial', 'ngMessages', 'ngMdIcons', 'datetime', "mdColors", 'nx.ui', 'froala', 'angular-repeat-n']);

app.filter('isArray', function() {
    return function (input) {
        return angular.isArray(input);
    };
});

app.filter('toDateMySQL', function() {
    return function(input) {
        return !input ? "" : app.convert.dateToPhp(input);
    }
});

app.filter('range', function(){
        return function(n) {
            var res = [];
            for (var i = 0; i < n; i++) {
                res.push(i);
            }
            return res;
        };
    });

app.directive('stringToNumber', function() {
    return {
        require: 'ngModel',
        link: function(scope, element, attrs, ngModel) {
            ngModel["$parsers"].push(function(value) {
                return '' + value;
            });
            ngModel["$formatters"].push(function(value) {
                return parseFloat(value);
            });
        }
    };
});

app.filter("trust", ['$sce', function($sce) {
    return function(htmlCode){
        return $sce.trustAsHtml(htmlCode);
    }
}]);

app.convert = {
   "phpToDate" : function(value){
       var m = moment(value, "YYYY-MM-DD HH:mm:ss", true);
       if (!m.isValid()) m = moment(value, "YYYY-MM-DD HH:mm", true);
       if (!m.isValid()) m = moment(value, "YYYY-MM-DD", true);
       if (m.isValid())
           return m.toDate();
       else
           return null;
   },
    /**
     * @param {Date} date
     * @returns {any}
     */
    "dateToPhp": function(date){
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    /**
     * @param {Date} date
     * @returns {any}
     */
    "dateToDisplay" : function(date){
        return moment(date).format("DD/MM/YYYY");
    },
    "phpToDisplay": function(value){
        return app.convert.dateToDisplay(app.convert.phpToDate(value));
    }
};

app.config(['$mdIconProvider', '$mdThemingProvider', '$mdDateLocaleProvider', AppConfig]);
app.controller('AppCtrl', ['$scope', '$mdBottomSheet','$mdSidenav', '$mdDialog', '$http', '$sce', '$compile', '$timeout', '$q', AppController]);


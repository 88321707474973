
    declare var baseUri:String;
    declare var baseUriApp:String;
    declare var appConfig:any;
    declare var moment:any;

    class AppConfig
    {

        constructor ($mdIconProvider, $mdThemingProvider, $mdDateLocaleProvider){

            $mdDateLocaleProvider.months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
                "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

            $mdDateLocaleProvider.shortMonths = [
                "ene.",
                "feb.",
                "mar.",
                "abr.",
                "may.",
                "jun.",
                "jul.",
                "ago.",
                "sep.",
                "oct.",
                "nov.",
                "dic"];

            $mdDateLocaleProvider.days = [
                "Domingo",
                "Lunes",
                "Martes",
                "Miércoles",
                "Jueves",
                "Viernes", "Sábado", "Domingo"];
            $mdDateLocaleProvider.shortDays = ['do.', 'lu.', 'ma.', 'mi.', 'ju.', 'vi.', 'sá.'];
            $mdDateLocaleProvider.firstDayOfWeek = 1;
            $mdDateLocaleProvider.msgCalendar = 'Calendario';
            $mdDateLocaleProvider.msgOpenCalendar = 'Abrir calendario';
            /*
            $mdDateLocaleProvider.parseDate = function(dateString) {
                var m = moment(dateString, 'L', true);
                var d = m.isValid() ? m.toDate() : new Date(NaN);
                d["ov"] = dateString;
                return d;
            };
            $mdDateLocaleProvider.formatDate = function(date) {
                var m = moment(date);
                return m.isValid() ? m.format('L') : (date && date["ov"] || '');
            }; */
            $mdIconProvider
                .iconSet('alert', baseUriApp + 'imgs/svg/alert-icons.svg', 24)
                .iconSet('action', baseUriApp + 'imgs/svg/action-icons.svg', 24)
                .iconSet('av', baseUriApp + 'imgs/svg/av-icons.svg', 24)
                .iconSet('communication', baseUriApp + 'imgs/svg/communication-icons.svg', 24)
                .iconSet('content', baseUriApp + 'imgs/svg/content-icons.svg', 24)
                .iconSet('device', baseUriApp + 'imgs/svg/device-icons.svg', 24)
                .iconSet('editor', baseUriApp + 'imgs/svg/editor-icons.svg', 24)
                .iconSet('file', baseUriApp + 'imgs/svg/file-icons.svg', 24)
                .iconSet('hardware', baseUriApp + 'imgs/svg/hardware-icons.svg', 24)
                .iconSet('icons', baseUriApp + 'imgs/svg/icons-icons.svg', 24)
                .iconSet('image', baseUriApp + 'imgs/svg/image-icons.svg', 24)
                .iconSet('maps', baseUriApp + 'imgs/svg/maps-icons.svg', 24)
                .iconSet('navigation', baseUriApp + 'imgs/svg/navigation-icons.svg', 24)
                .iconSet('notification', baseUriApp + 'imgs/svg/notification-icons.svg', 24)
                .iconSet('social', baseUriApp + 'imgs/svg/social-icons.svg', 24)
                .iconSet('toggle', baseUriApp + 'imgs/svg/toggle-icons.svg', 24);

            var crmThemeMap = $mdThemingProvider.extendPalette(appConfig.appearance.themeColor, {
                'contrastDefaultColor': 'light',
                'contrastDarkColors': ['50'],
                '50': 'ffffff'
            });

            $mdThemingProvider.definePalette('crmTheme', crmThemeMap);

            $mdThemingProvider.theme('red').accentPalette('red', {"default": '400'});
            $mdThemingProvider.theme('blue').accentPalette('blue', {"default": '400'});

            $mdThemingProvider.theme('default')
                .primaryPalette('crmTheme', {
                    'default': '500', 
                    'hue-1': '50'
                })
                .backgroundPalette('grey', {
                    'default': '200'
                })
                .accentPalette('blue-grey');

            $mdThemingProvider.theme('input', 'default')
                .primaryPalette('grey')

        }
    }

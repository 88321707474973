///<reference path="..\..\def\jquery.d.ts"/>

    declare var app;

    class AppController
    {
        public angular;
        constructor ($scope, $mdBottomSheet, $mdSidenav, $mdDialog, $http, $sce, $compile, $timeout, $q){

            var app_config = window["app_config"];

            app["appController"] = this;

            app["angular"] = {
                "dialog" : $mdDialog,
                "scope" : $scope,
                "http" : $http,
                "sce" : $sce,
                "compile" : $compile
            };

            var filter = {
                "valid" : function(value, query){
                    var lowercaseQuery = angular.lowercase(query);
                    var lowercaseValue = angular.lowercase(value);
                    return lowercaseValue && (lowercaseValue.indexOf(lowercaseQuery) > -1);
                },
                "createFilterFor" : function (query) {
                    return function filterFn(state) {
                        return filter.valid(state.value, query);
                    };
                }
            };

            window["stopProp"] = function(event) {
                event.stopPropagation();
            };

            $scope.filterSelectOption = function(value, query){
                if (!query) return true;
                return filter.valid(value, query);
            };

            $scope.filterAutocompleteItems = function(query, items){
                var results = query ? items.filter( filter.createFilterFor(query) ) : items;
                var deferred = $q.defer();
                $timeout(function () { deferred.resolve( results ); }, Math.random() * 1000, false);
                return deferred.promise;
            };

            $scope.toggleSidenav = function(menuId) {
                $mdSidenav(menuId).toggle();
            };

            $scope.nav = function(url){

                if (window.event.button !== 0 && window.event.button !== 1) return;

                var newTab = (window.event.button == 1) || (window.event.ctrlKey);

                if (newTab) {
                    window.open(url);
                }
                else
                    document.location.href = url;
            };

            $scope.menu = app_config.menu;
            $scope.alert = '';

            $scope.ckeOptions = {
                language: 'es',
                allowedContent: true,
                entities: false
            };

            $scope.rangeNum = function(count){
                return new Array(count);
            };

            $scope.showDatePicker = function(data, name){

                var d = app.convert.phpToDate(data[name]);
                if (!d) d = new Date();
                d = moment(d);

                var picker = new MaterialDatetimePicker({
                    "default": d
                }).on('submit', function (val) {
                    if (val) {
                        var d = app.convert.dateToPhp(val.toDate());
                        //app.angular.scope.$apply(function () {
                            //data[name] = d;
                        //});
                        $("[form='"+name+"']").val(d);
                        console.log("[form='" + name + "']");
                    }
                }).on('open', function () {
                }).on('close', function () {
                });

                picker.open();

            };

            $scope.showListBottomSheet = function($event) {
                $scope.alert = '';
                $mdBottomSheet.show({
                    template: '<md-bottom-sheet class="md-list md-has-header"> <md-subheader>Settings</md-subheader> <md-list> <md-item ng-repeat="item in items"><md-item-content md-ink-ripple flex class="inset"> <a flex aria-label="{{item.name}}" ng-click="listItemClick($index)"> <span class="md-inline-list-icon-label">{{ item.name }}</span> </a></md-item-content> </md-item> </md-list></md-bottom-sheet>',
                    controller: 'ListBottomSheetCtrl',
                    targetEvent: $event
                }).then(function(clickedItem) {
                    $scope.alert = clickedItem.name + ' clicked!';
                });
            };

            $(function(){
                var oldB = false;
                $('md-content.app-content').scroll(function(){
                    var $l = $(".app-content-scrollshadow");
                    var b = $(this).scrollTop() > 20;
                    if (b != oldB) {
                        if (b)
                            $l.addClass("active");
                        else
                            $l.removeClass("active");
                    }
                    oldB = b;
                });
            });

        }
    }